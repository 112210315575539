.absolute--fill {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.dropdown-toggle.no-caret::after {
  display: none !important;
}
select,
option {
  text-transform: capitalize !important;
}
