.addBanner__info {
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
}
.addBanner__info__header {
  border-radius: 10px 10px 0 0;

  background: var(--color-primary);
  display: flex;
  padding: 5px 10px;
  align-items: center;
}
.addBanner__info__header p {
  text-align: start;
  font-weight: 500;
  margin: 0;
}
.add_banner_input {
  flex: 1;
}
.addBanner__top__carousel {
  border: 1px solid red;
}
.addBanner__section {
  padding: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #bebebe;
  margin: 10px;
  border-radius: 10px;
}
.addBanner__sub__header {
  padding: 5px 20px;
}
.addBanner__sub__header h2 {
  text-align: start;
  font-size: 1.2rem;
}
@media (max-width: 1000px) {
  .add_banner_input h3 label {
    font-size: 1.1rem !important;
    margin: 0;
    padding: 0;
  }
  .add_banner_input .input__image__div__wrapper {
    width: 100%;
  }
}
