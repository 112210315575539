.crud-table-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 50px #4242426b;
  padding: 1rem;
  padding-bottom: 1px;
  & .table-responsive {
    height: calc(100vh - 214px);
  }
  & .crud-table {
    thead {
      overflow: auto;
      & th {
        background-color: white;
        position: sticky;
        top: 0;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
    & tbody {
      td {
        white-space: nowrap;
      }
    }
    & .action-btn {
      border-radius: 11px;
      margin-right: 0.25rem;
    }
  }
}
