.input__image__div {
  height: 300px;
}
.input__image__div__wrapper {
  position: relative;
  height: 220px;
  border-radius: 10px;
  background: #fff;
  border: 2px dashed #c2cdda;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.input__image {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input__image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.input__image__content__icon {
  font-size: 3rem;
  color: #9658fe;
}
.input__image__content {
  text-align: center;
}
.input__image__content p {
  font-size: 1rem;
  font-weight: 500;
  color: #9658fe;
}
.input__image__cancel__btn {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: #9658fe;
}
.input__imge__file__name {
  position: absolute;
  bottom: 0px;
  background: linear-gradient(135deg, #3a8ffe 0%, #9658fe 100%);
  width: 100%;
  color: #fff;
  padding: 8px 0;
  font-size: 18px;
  display: none;
}
.input__image__file label {
  margin-top: 30px;
  width: 100%;
  height: 30px;
  display: block;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 1rem;

  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  background: linear-gradient(135deg, #3a8ffe 0%, #9658fe 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.input__image__header {
  font-size: 1.5rem;
  text-align: start;
}
.input__image__control {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}
.input__image__div__wrapper:hover > .input__image__control {
  display: flex;
}
