.sidebar-wrapper {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #548cff;

  .logo {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    margin: 0 12px;
    h4 {
      color: white;
      text-align: center;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  .sidebar {
    padding: 12px;
    height: 90vh;
    overflow-y: auto;

    li {
      flex: 1;
      margin-bottom: 8px;
      & > .nav-link {
        padding: 0.6rem 1rem;
        border-radius: 8px;
        will-change: background-color;
        transition: background-color 200ms ease-out;
        * {
          font-weight: 600;
          align-items: center;
          color: white;
          will-change: color;
          transition: color 200ms ease-out;
        }
        &:hover {
          background-color: white;
          * {
            color: #548cff;
          }
        }
      }
      &.active {
        > .nav-link {
          background-color: white;
          * {
            color: #548cff;
          }
        }
      }
    }
  }
}
