.meeting-container {
  background-color: #f5f7ff;
}

.video {
  border-radius: 20px;
  max-height: 350px;
}

.controls-container {
  padding: 8px 0;
  transition: padding 350ms ease-in-out;
  bottom: 0;
}
.controls-container:hover {
  padding: 16px 0;
}
.hangup-icon {
  transform: rotate(225deg);
}

.ss-btn {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.stream:hover .ss-btn {
  opacity: 1;
}
